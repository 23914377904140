import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { YMInitializer } from 'react-yandex-metrika'
import ReactGA from 'react-ga'
import AOS from 'aos'
import 'aos/dist/aos.css'

import Header from './components/Header'
import Testimonials from './components/Testimonials'
import Contacts from './components/Contacts'
import Ymap from './components/Ymap'
import Footer from './components/Footer'

import icon1 from './images/pluses/law.svg'
import icon2 from './images/pluses/clock.svg'
import icon3 from './images/pluses/logis.svg'

class App extends React.Component {
  componentDidMount() {
    AOS.init({
      disable: 'mobile', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 80, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 700, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    })

    ReactGA.initialize('UA-146292194-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <div className="page">
          <main className="main">

            <section className="hero">
              <div className="hero__bg">
                <div className="hero__overlay"></div>
              </div>
              <div className="wrapper">
                <div className="hero__inner" data-aos="fade-right">
                  <h1 className="hero__title">Лопанов Максим Николаевич</h1>
                  <div className="hero__subtitle">Ритуальные услуги города Пушкино</div>
                  <AnchorLink className="hero__btn btn" href="#contacts">Связаться</AnchorLink>
                </div>
              </div>
              <div className="hero__arrow">
                <AnchorLink className="hero__arrow-img" href="#about">Вниз</AnchorLink>
              </div>
            </section>

            <section className="about" id="about">
              <div className="wrapper">
                <div className="about__inner" data-aos="fade-right">
                  <h2 className="about__title">О КОМПАНИИ</h2>
                  <p className="about__text">
                    Ритуальное агентство ООО «Посбон» занимается оказанием полного комплекса ритуальных услуг с 1997 года. ООО «ПОСБОН» укомплектовано квалифицированными специалистами, располагает собственной производственной базой по изготовлению ритуальных принадлежностей и надгробных сооружений из натурального камня, а также располагает парком специализированного автотранспорта.
                  </p>
                </div>
              </div>
            </section>

            <section className="pluses">
              <div className="wrapper">
                <div className="pluses__inner">
                  <div className="pluses__item" data-aos="fade-up" data-aos-delay="50">
                    <img className="pluses__img" src={icon1} alt="Полный комплекс ритуальных услуг" />
                    <h3 className="pluses__title">Полный комплекс ритуальных услуг</h3>
                  </div>
                  <div className="pluses__separator"></div>
                  <div className="pluses__item" data-aos="fade-up" data-aos-delay="100">
                    <img className="pluses__img" src={icon2} alt="Быстрое время исполнения заказа" />
                    <h3 className="pluses__title">Быстрое время исполнения заказа</h3>
                  </div>
                  <div className="pluses__separator"></div>
                  <div className="pluses__item" data-aos="fade-up" data-aos-delay="150">
                    <img className="pluses__img" src={icon3} alt="Большой выбор продукции" />
                    <h3 className="pluses__title">Большой выбор продукции</h3>
                  </div>
                </div>
              </div>
            </section>

            <section className="services" id="services">
              <div className="wrapper">
                <div className="services__inner">
                  <h2 className="services__title" data-aos="fade-up">
                    Оказание полного комплекса ритуально-похоронных услуг по погребению и кремации умерших по городу Пушкино
                  </h2>
                  <div className="services__items">
                    <div className="services__item">
                      <AnchorLink className="services__link" href="#contacts" data-aos="zoom-in" data-aos-delay="50">
                        <div className="services__link-img--one">
                          <div className="services__link-overlay">
                            <h3 className="services__link-title">Комплексное оформление документов</h3>
                          </div>
                        </div>
                      </AnchorLink>
                      <p className="services__item-text">Полное и последовательное оформление нужных документов.</p>
                      <AnchorLink className="services__item-btn btn" href="#contacts">Оставить заявку</AnchorLink>
                    </div>
                    <div className="services__item">
                      <AnchorLink className="services__link" href="#contacts" data-aos="zoom-in" data-aos-delay="100">
                        <div className="services__link-img--two">
                          <div className="services__link-overlay">
                            <h3 className="services__link-title">Предоставление и доставка ритуальных принадлежностей</h3>
                          </div>
                        </div>
                      </AnchorLink>
                      <p className="services__item-text">Предоставление принадлежностей для похорон из огромного каталога.</p>
                      <AnchorLink className="services__item-btn btn" href="#contacts">Оставить заявку</AnchorLink>
                    </div>
                    <div className="services__item">
                      <AnchorLink className="services__link" href="#contacts" data-aos="zoom-in" data-aos-delay="150">
                        <div className="services__link-img--three">
                          <div className="services__link-overlay">
                            <h3 className="services__link-title">Предоставление автокатафального транспорта</h3>
                          </div>
                        </div>
                      </AnchorLink>
                      <p className="services__item-text">Выбор катафального транспорта из парка машин и на заказ.</p>
                      <AnchorLink className="services__item-btn btn" href="#contacts">Оставить заявку</AnchorLink>
                    </div>
                    <div className="services__item">
                      <AnchorLink className="services__link" href="#contacts" data-aos="zoom-in" data-aos-delay="50">
                        <div className="services__link-img--four">
                          <div className="services__link-overlay">
                            <h3 className="services__link-title">Предоставление пассажирского транспорта</h3>
                          </div>
                        </div>
                      </AnchorLink>
                      <p className="services__item-text">Предоставление отдельного транспорта для родственников.</p>
                      <AnchorLink className="services__item-btn btn" href="#contacts">Оставить заявку</AnchorLink>
                    </div>
                    <div className="services__item">
                      <AnchorLink className="services__link" href="#contacts" data-aos="zoom-in" data-aos-delay="100">
                        <div className="services__link-img--five">
                          <div className="services__link-overlay">
                            <h3 className="services__link-title">Оформление участков на кладбище</h3>
                          </div>
                        </div>
                      </AnchorLink>
                      <p className="services__item-text">Подбор любого участка на выбранном вами кладбище.</p>
                      <AnchorLink className="services__item-btn btn" href="#contacts">Оставить заявку</AnchorLink>
                    </div>
                    <div className="services__item">
                      <AnchorLink className="services__link" href="#contacts" data-aos="zoom-in" data-aos-delay="150">
                        <div className="services__link-img--six">
                          <div className="services__link-overlay">
                            <h3 className="services__link-title">Отправка в любую точку страны и зарубеж</h3>
                          </div>
                        </div>
                      </AnchorLink>
                      <p className="services__item-text">Возможна транспортировка усопшего в удобное местоположение.</p>
                      <AnchorLink className="services__item-btn btn" href="#contacts">Оставить заявку</AnchorLink>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Testimonials />

            <Contacts />

            <Ymap />

          </main>
        </div>

        <Footer />
        <YMInitializer accounts={[61856725]} />
      </React.Fragment>
    );
  }
}

export default App;
