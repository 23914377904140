import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import logo from '../images/logo.svg'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isToggleOn: false,
      headerDark: false
    }

    this.headerScroll = this.headerScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.headerScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.headerScroll)
  }

  headerScroll() {
    if (window.scrollY > 50) {
      this.setState({
        headerDark: true
      })
    } else {
      this.setState({
        headerDark: false
      })
    }
  }

  toggleMenu() {
    this.setState({
      isToggleOn: !this.state.isToggleOn
    })
  }

  render() {
    let headerClass = this.state.headerDark ? 'header--dark' : ''
    let navClass = this.state.isToggleOn ? 'header__nav--open' : ''
    let navToggleClass = this.state.isToggleOn ? 'burger--active' : ''

    return (
      <header className={`header ${headerClass}`} id="header">
        <div className="wrapper">
          <div className="header__inner">
            <a className="header__logo" href="/">
              <img className="header__logo-img" src={logo} alt="Ритуальные услуги города Пушкино. Агент Лопанов Максим Николаевич." />
            </a>
            <nav className={`header__nav ${navClass}`} id="nav">
              <AnchorLink className="header__nav-link" href="#about" onClick={() => this.toggleMenu()}>О компании</AnchorLink>
              <AnchorLink className="header__nav-link" href="#services" onClick={() => this.toggleMenu()}>Услуги</AnchorLink>
              <AnchorLink className="header__nav-link" href="#testimonials" onClick={() => this.toggleMenu()}>Отзывы</AnchorLink>
              <AnchorLink className="header__nav-link" href="#contacts" onClick={() => this.toggleMenu()}>Контакты</AnchorLink>
              <a className="header__nav-link--btn btn" href="tel:+74959985588">+7 (495) 998-55-88</a>
            </nav>
            <button className={`burger ${navToggleClass}`} id="navToggle" type="button" aria-label="Меню" onClick={() => this.toggleMenu()}>
              <span className="burger__line"></span>
              <span className="burger__line"></span>
              <span className="burger__line"></span>
            </button>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
