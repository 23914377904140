import React from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function Testimonials() {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <section className="testimonials" id="testimonials">
      <div className="wrapper">
        <div className="testimonials__inner">
          <h2 className="testimonials__title" data-aos="fade-up">Отзывы</h2>
          <div className="testimonials__carousel">
            <Slider {...settings}>
              <div className="testimonials__item">
                <p className="testimonials__item-text">
                  9 апреля 2019 года в нашей семье случилось горе - умерла мама. Знакомые посоветовали обратиться в ритуальное агенство "Посбон". Трудность состояла в том, что нам нужно было провести захоронение в г.Владикавказе. Сотрудник агенства Лопанов Максим дал нам всю нужную информацию, ненавязчиво были предложены все необходимые услуги. Организация прощания, отпевания, транспортировки была проведена на высоком профессиональном уровне. Большое Вам спасибо.
                </p>
                <div className="testimonials__item-footer">
                  <h4 className="testimonials__item-name">Марина Македонова</h4>
                  <div className="testimonials__item-stars">
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                  </div>
                </div>
              </div>
              <div className="testimonials__item">
                <p className="testimonials__item-text">
                  Выражаем огромную благодарность Лопанову Максиму Николаевичу. Профессионал с большой буквы, внимательный, тактичный,  очень рекомендую.
                </p>
                <div className="testimonials__item-footer">
                  <h4 className="testimonials__item-name">Алина Юдина</h4>
                  <div className="testimonials__item-stars">
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                  </div>
                </div>
              </div>
              <div className="testimonials__item">
                <p className="testimonials__item-text">
                  Очень помогли нам в непростой ситуации, когда важна была точность и спокойствие. Вся церемония была организована хорошо, у нас нет никаких замечаний. Спасибо за профессионализм
                </p>
                <div className="testimonials__item-footer">
                  <h4 className="testimonials__item-name">Фёдор Алексеев</h4>
                  <div className="testimonials__item-stars">
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                  </div>
                </div>
              </div>
              <div className="testimonials__item">
                <p className="testimonials__item-text">
                  Спасибо большое от всей нашей семьи. Очень выручили в трудный момент. Как хорошо, что сейчас есть компании, которые помогут в трудный момент и, можно сказать, сделают все от и до за нас. Спасибо большое за ваш нелегкий труд.
                </p>
                <div className="testimonials__item-footer">
                  <h4 className="testimonials__item-name">Пётр Щербаков</h4>
                  <div className="testimonials__item-stars">
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                  </div>
                </div>
              </div>
              <div className="testimonials__item">
                <p className="testimonials__item-text">
                  Обратились в феврале этого года провести похоронную церемонию усопшего родственника. Мероприятие было проведено в соответствии со всеми нашими пожеланиями, качественно и организованно. Спасибо человеческое.
                </p>
                <div className="testimonials__item-footer">
                  <h4 className="testimonials__item-name">Снежана Мамонтова</h4>
                  <div className="testimonials__item-stars">
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                    <span className="testimonials__item-star"></span>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
