import React from 'react'
import emailjs from 'emailjs-com'

class Contacts extends React.Component {
  componentDidMount() {
    window.addEventListener('click', this.closeModal)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeModal)
  }

  closeModal(event) {
    const modal = document.getElementById('modal')

    if (event.target === modal) {
      modal.style.display = 'none'
    }
  }

  render() {
    function sendEmail(event) {
      event.preventDefault()

      document.getElementById('submit-btn').value = 'Отправка...'

      emailjs.sendForm('service_vganq0n', 'template_3od8yks', event.target, 'user_7rnA6BYu9AVPBC9ftyUGg')
        .then(() => {
          document.getElementById('contact-form').reset()
          document.getElementById('modal').style.display = 'flex'
          document.getElementById('submit-btn').value = 'Отправить заявку'
        }, (error) => {
          console.log(error.text)
        })
    }

    function closeModalButton(event) {
      event.preventDefault()

      document.getElementById('modal').style.display = 'none'
    }

    return (
      <React.Fragment>
        <section className="contacts" id="contacts">
          <div className="wrapper">
            <div className="contacts__inner">
              <h2 className="contacts__title" data-aos="fade-up">Контакты</h2>
              <div className="contacts__content">
                <div className="contacts__heading" data-aos="fade-right">
                  <div className="contacts__heading-main">
                    <span>Телефон: </span><a href="tel:+74959985588">+7 (495) 998-55-88</a>
                    <br />
                    <span>Почта: </span><a href="mailto:55978@mail.ru">55978@mail.ru</a>
                  </div>
                  <div className="contacts__heading-address">
                    <strong>Адрес: </strong><span>Московская область, г. Пушкино, ул. Учинская, д. 23а</span>
                    <br />
                    <strong>Время работы агентской службы: </strong><span>Круглосуточно</span>
                    <br />
                    <strong>Время работы магазина: </strong><span>09:00 — 17:00</span>
                  </div>
                </div>
                <form className="contacts__form" id="contact-form" onSubmit={sendEmail} data-aos="fade-left">
                  <div className="contacts__form-group">
                    <input className="contacts__form-input" id="name" name="customer_name" type="text" placeholder="Ваше имя" required />
                  </div>
                  <div className="contacts__form-group">
                    <input className="contacts__form-input" id="phone" name="customer_phone" type="tel" placeholder="Ваш телефон" required />
                  </div>
                  <div className="contacts__form-group">
                    <textarea className="contacts__form-textarea" id="message" name="message" placeholder="Ваше сообщение"></textarea>
                  </div>
                  <input className="contacts__form-btn btn" id="submit-btn" value="Отправить заявку" type="submit" />
                  <p className="contacts__form-text">
                    Нажимая на кнопку «Отправить заявку», вы даете согласие на обработку персональных данных и соглашаетесь c политикой конфиденциальности.
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
        <div className="contacts__modal" id="modal">
          <div className="contacts__modal-content">
            <div className="contacts__modal-title">Спасибо, ваша заявка принята!</div>
            <p className="contacts__modal-text">Мы свяжемся с вами в ближайшее время.</p>
            <button className="contacts__modal-btn btn" id="close-modal" onClick={closeModalButton}>Закрыть</button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Contacts
