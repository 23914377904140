import React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="wrapper">
          <div className="footer__inner">
            <div className="footer__copyrights">© 2021 Все права защищены – Лопанов Максим Николаевич</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
