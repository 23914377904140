import React from 'react'
import { YMaps, Map, Placemark } from 'react-yandex-maps'

class YMap extends React.Component {
  render() {
    return (
      <div className="map" id="map">
        <YMaps>
          <Map defaultState={{center: [55.997737, 37.851006], zoom: 18}} width="100%" height="100%">
            <Placemark geometry={[55.997737, 37.851006]} />
          </Map>
        </YMaps>
      </div>
    )
  }
}

export default YMap
